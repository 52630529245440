

.ci-phone:before {
  content: "\e908";
}

.ci-edit:before {
  content: "\e900";
}

.ci-heart:before {
  content: "\e901";
}

.ci-heart-solid:before {
  content: "\e902";
}

.ci-plus:before {
  content: "\e903";
}

.ci-search:before {
  content: "\e904";
}

.ci-trash:before {
  content: "\e905";
}

.ci-email:before {
  content: "\e906";
}

.ci-back:before {
  content: "\e907";
}

.ci-person:before {
  content: "\e909";
}

.ci-upload:before {
  content: "\e90a";
}

.ci-times:before {
  content: "\e90b";
}

.cir {
  font-family: 'Custom Icons Regular';
  font-weight: 300;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
@font-face {
  font-family: 'Custom Icons Regular';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url("../../custom-icons/icomoon.eot");
  src: url("../../custom-icons/icomoon.eot?#iefix") format("embedded-opentype"), url("../../custom-icons/icomoon.woff") format("woff"), url("../../custom-icons/icomoon.ttf") format("truetype"), url("../../custom-icons/icomoon.svg#fontawesome") format("svg");
}

