

header {
    background: linear-gradient(182.65deg, #78C9CE 0%, #2496A2 99.98%, #2496A2 100%);
    border-bottom: 7px solid rgba(255, 255, 255, 0.3);

    .logo {
        display: inline-flex;
        justify-content: center;
        padding: 19px 16px 14px 16px;

        img {
            height: 20px;
        }
    }

    @include media-breakpoint-down(sm) {
        .logo {
            padding-top: 14px;
            padding-bottom: 12px;

            img {
                height: 17px;
            }
        }
    }
}

.contacts-filter {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray-400;
    padding: 20px 0;
    border-bottom: 1px solid $primary;

    .filter-param {
        display: block;
        transition: 0.3s color;
        cursor: pointer;
        padding: 30px;

        &.active, &:hover {
            color: $primary;
        }
    }

    .separator {
        display: block;
        width: 1px;
        height: 15px;
        background-color: $gray-400;
        flex-shrink: 0;
    }
}

.contacts-search {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 58px;
    padding-bottom: 61px;

    .form-group {
        position: relative;
        width: 540px;
        max-width: 100%;
        margin-bottom: 0;
    }

    .form-control {
        padding-left: 56px;
        z-index: 1;
        position: relative;
        box-shadow: 0 2px 34px 0 $gray-200;
    }

    i {
        position: absolute;
        top: 50%;
        left: 23px;
        transform: translateY(-50%);
        font-size: 18px;
        color: $gray-400;
        z-index: 0;
    }
}

.contacts-wrap {
    max-width: 1170px;
    margin: 0 auto;

    .contact-card-wrap {
        margin-bottom: 29px;
    }

    .contact-card {
        padding: 15px 17px 23px 15px;
        border-radius: 0.25rem;
        border: 1px solid rgba(187,196,195,0.4);
        display: flex;
        flex-direction: column;
        height: 100%;
        transition: 0.3s border-color;

        &.add-new {
            border-style: dashed;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-color: rgba(45, 160, 173, 0.5);
            color: rgba(45, 160, 173, 0.5);
            transition: 0.3s color, 0.3s border-color;
            min-height: 142px;

            i {
                margin-bottom: 11px;
            }

            &:hover {
                color: $primary;
                border-color: $primary;
            }
        }

        

        &:hover {
            border-color: $primary;

            @include media-breakpoint-up(lg) {
                .user-actions {
                    > * {
                        &:nth-child(2), &:nth-child(3) {
                            opacity: 1;
                        }
                    }
                }
            }

        }

        .user-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: -10px;

            img {
                width: 66px;
                height: 66px;
                border: 3px solid rgba(187,196,195,0.3);
                border-radius: 50%;
                margin-bottom: 13px;
            }
            span {
                @extend .body-1;
                color: $gray-400;
            }
        }
    }

    @include media-breakpoint-down(md) {

        .contact-card-wrap {
            margin-bottom: 10px;
            padding-left: 8px;
            padding-right: 8px;
        }

        .contact-card {
            flex-direction: row;
            padding: 7px 8px 7px 13px;
            

            .user-info {
                order: 1;
                flex-direction: row;
                margin-top: 0;

                img {
                    margin-bottom: 0;
                    margin-right: 25px;
                    width: 46px;
                    height: 46px;
                }
            }

            .user-actions {
                order: 2;
                margin-left: auto;

                > * {
                    padding: 16px;
                    
                    /* &:nth-child(2) {
                        margin-right: 0;
                    } */
                    margin-right: 0;
                }
            }

            &.add-new {
                flex-direction: row;
                justify-content: flex-start;
                padding: 18px 25px;
                font-size: 0.875rem;
                min-height: auto;

                i {
                    margin-bottom: 0;
                    margin-right: 18px;
                    font-size: 1.125rem;
                }
            }
        }
    }
}

.user-actions {
    display: flex;
    align-items: center;
    margin: -8px;
    position: relative;
    z-index: 1;

    > * {
        color: $gray-400;
        font-size: 1.125rem;
        padding: 8px;
        display: block;

        &:first-child {
            margin-right: auto;
        }

        &:nth-child(2) {
            margin-right: 10px;
        }

        @include media-breakpoint-up(lg) {
            &:nth-child(2), &:nth-child(3) {
                transition: 0.3s opacity;
                opacity: 0;
            }

        }
    }
}

.user-actions {
    .main-line {
        > * {
            &:first-child {
                margin-right: auto;
            }
    
            &:nth-child(2) {
                margin-right: 18px;
            }
        }
    }
}

.user-details, .add-user {
    max-width: 835px;
    padding-top: 57px;

    .avatar {
        background-color: rgba(45,161,173,0.4);
        display: flex;
        align-items: center;
        justify-content: center;

        &, & img {
            height: 186px;
            width: 186px;
            border: 3px solid rgba(187,196,195,0.3);
            border-radius: 50%;
        }

        i {
            font-size: 20px;
            color: $white;
        }

        & {
            border: none;
            box-shadow: 0 0 0 3px rgba(187,196,195,0.3);
        }
    }

    .main-line {
        display: flex;
        align-items: center;
        min-height: 93px;
        border-bottom: 1px solid $primary;
        margin-bottom: 63px;
        padding-top: 24px;

        * {
            color: $gray-400;
        }

        .btn:not(:first-child) {
            margin-left: 36px;

            @include media-breakpoint-down(xs) {
                margin-left: 18px;
            }
        }

        .name {
            font-weight: 700;
            font-size: 1.75rem;
            line-height: 2.125rem;
            margin-right: auto;
        }
        
    
        > .btn:nth-child(1) {
            font-size: 16px;
            margin-right: 8px;
        }
    }

    .user-info {
        display: grid;
        grid-template-columns: 125px auto;
        margin-left: 63px;
        color: $gray-400;

        > div {
            margin-bottom: 71px;

            &:nth-child(odd) {
                font-weight: 700;
                color: $primary;
                // display: flex;
                // align-items: center;

                i {
                    font-size: 10px;
                    margin-right: 15px;
                    margin-top: 4px;

                    &.ci-phone {
                        font-size: 15px;
                    }
                }
            }
        }
    
        .numbers-grid {
            display: grid;
            grid-template-columns: auto 1fr;
            margin-top: 2px;

            * {
                // color: $gray-400 !important;
            }
            

            > div {
                margin-bottom: 43px;
                line-height: 1.5rem;

                &:nth-child(odd) {
                    font-size: 0.875rem;
                    text-transform: uppercase;
                    font-weight: 700;
                    margin-right: 20px;
                }

                &:nth-child(even) {
                    @extend .body-2;
                    text-decoration: underline;
                }
            }
        }
    }

    .user-actions {
        > * {
            opacity: 1;
            margin-right: initial;
        }
    }

    .mobile-user-display {
        display: flex;
        align-items: center;
        padding-bottom: 22px;
        border-bottom: 1px solid $primary;
        margin-bottom: 23px;

        .avatar {
            width: 56px;
            height: 56px;
            border-radius: 50%;
            border: 3px solid rgba(187,196,195,0.3);
            margin-right: 23px;
        }

        .name {
            font-size: 1.3125rem;
            color: $gray-400;
            font-weight: 700;
        }
    }

    @include media-breakpoint-down(sm) {
        padding-left: 22px;
        padding-right: 22px;
        padding-top: 0;

        .main-line {
            min-height: auto;
            margin-bottom: 20px;
            border-color: rgba(187, 196, 195, 0.4);

            &.mobile {
                // display: flex;
                margin-left: -22px;
                margin-right: -22px;
                padding-left: 22px;
                padding-right: 22px;
                padding-top: 4px;
                padding-bottom: 4px;

                .user-actions {
                    margin: 0;
                }
            }
        }

        .user-info {
            margin-left: 25px;

            @include media-breakpoint-down(xs) {
                grid-template-columns: auto;

                > div {
                    margin-bottom: 12px;

                    &:nth-child(odd) {
                        i {
                            margin-right: 9px;
                        }
                    }

                    &:nth-child(even) {
                        padding-left: 24px;
                        margin-bottom: 50px;
                    }
                }

                .numbers-grid {
                    margin-top: 10px;

                    > div {
                        margin-bottom: 32px;
                    }
                }
            }
        }
    }
}

.user-details {
    .avatar {
        // padding: 0;
        background-color: transparent;
        box-shadow: none;
        width: auto;
        height: auto;
    }
}

.add-user {
    .avatar {
        width: 183px;
        height: 183px;
    }

    .main-line {
        margin-bottom: 17px;
    }

    .input-fileds {
        .form-group {
            padding-bottom: 30px;
            border-bottom: 1px solid $primary;
            label {
                color: $primary;
                margin-bottom: 22px;

                i {
                    margin-right: 15px;
                    font-size: 15px;

                    &.ci-email {
                        font-size: 10px;
                    }
                }
            }

            .form-control {
                max-width: 100%;
                color: $gray-400;

                &.width-limited {
                    width: 300px;
                }
            }

            &:last-child {
                border-bottom: none;
            }

            .input-collection {
                display: flex;
                align-items: center;
                margin-bottom: 30px;

                .form-control {
                    margin-right: 30px;

                    &:not(:nth-child(1)) {
                        width: auto;
                    }

                    &:first-child {
                        width: 300px;
                    }
                }

                &:last-child {
                    .form-control {
                        border-color: $primary;
                    }

                    .remove-row {
                        @extend .btn-outline-primary;
                    }
                }
            }

            .remove-row, .add-new-row button {
                width: 32px;
                height: 32px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 8px;
                border-radius: 50%;
                flex-shrink: 0;
            }

            .add-new-row {
                display: inline-flex;
                align-items: center;
                // margin-top: 43px;
                cursor: pointer;
                user-select: none;
            
                span {
                    font-size: 0.875rem;
                    color: $primary;
                    margin-left: 15px;
                }
            }
        }
    }

    .new-user-actions {
        margin-top: 100px;

        button {
            width: 160px;
        }
    }

    @include media-breakpoint-down(sm) {
        // .input-fileds 
    }

    @include media-breakpoint-down(md) {
        .mobile-user-display {
            // background-color: transparent;
    
            .avatar {
                width: 156px;
                height: 156px;
                margin: 0 auto;
                border: none;
                // background-color: transparent;
            }
        }

        .input-fileds {
            .form-group {
                .form-control, .form-control.width-limited {
                    width: 100%;
                }
                .input-collection {
                    flex-wrap: wrap;
                    margin-bottom: 40px;

                    .form-control {
                        flex-grow: 1;
                        margin-right: 0;

                        &:not(:nth-child(1)) {
                            flex-shrink: 1;
                            flex-grow: 1;
                            margin-right: 18px;
                        }

                        &:first-of-type {
                            width: 100%;
                            margin-bottom: 10px;
                        }
                    }
                }
            }

            .remove-row {
                flex-shrink: 0;
            }
        }
    }
}

.delete-modal {
    .modal-dialog {
        max-width: 480px;
        color: $gray-400;

        .modal-content {
            border: none;
            box-shadow: 0 0 14px 0 rgba(99,109,255,0.27);
        }

        .modal-header {
            padding: 20px 31px;
            font-weight: 700;
            border-color: #DCDCDC;
        }

        .modal-body {
            padding: 30px 31px;

            p {
                margin-bottom: 50px;
                text-align: center;
            }

            .btn {
                width: 160px;
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .filter-search-container {
        padding-left: 28px;
        padding-right: 28px;
    }

    .contacts-filter {
        padding-top: 0;
        padding-bottom: 0;

        .filter-param {
            padding: 16px;
            flex-grow: 1;
            text-align: center;
            flex-basis: 0;
        }
    }

    .contacts-search {
        padding-top: 23px;
        padding-bottom: 25px;
        margin-bottom: 10px;

        .form-control {
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            height: auto;
        }
    }
}